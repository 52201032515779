@use "../base/vars" as *;

.contact{
    margin: 30px auto 0;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 616px;
    gap: 45px;
    
    @include media_query(){
        grid-template-columns: 1fr;
    }
    
    .contact_inner{
        padding: 60px 0 0 120px;
        
        background-image: url(../img/contact/bg01.webp);
        background-repeat: no-repeat;
        background-position: bottom left 150px;
        background-size: 272px auto;
        
        @include media_query(){
            padding: 0 0 80px 30px;
            background-position: bottom 20px left 150px;
            background-size: 232px auto;
        }
        
        .section_title{
            margin-bottom: 65px;
                
            @include media_query(){
                margin-bottom: 40px;
            }
        }
        
        h3{
            margin-bottom: 30px;
            @include rem(19);
            letter-spacing: .185em;
            
            @include media_query(){
                @include rem(18);
            }
        }
        
        p{
            @include rem(15);
            letter-spacing: .15em;
            line-height: 1.8;
            
            @include media_query(){
                @include rem(12);
            }
        }
    }
    
    .contact_img{
        @include media_query(){
            order: -1;
            padding-left: 65px;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
}











.contact_form{
    max-width: 1100px;
    margin: 90px auto 0;
    
    background-image: url(../img/contact/bg02.webp), url(../img/contact/bg03.webp);
    background-repeat: no-repeat;
    background-position: bottom 90px right, bottom left 120px;
    background-size: 221px auto, 114px auto;
    
    @include media_query(){
        margin: 0;
        padding: 0 35px;
        background-position: bottom 210px right -40px, bottom left;
        background-size: 164px auto, 88px auto;
    }
    
    .contact_form_inner{
        max-width: 920px;
        margin: 0 auto;
        border-top: solid 2px #2872A4;
    }
    
    label{
        @include rem(19);
        color: #2872A4;
        font-weight: bold;
        
        @include media_query(){
            @include rem(15);
        }
    }
    
    .screen-reader-response{
        margin-bottom: 20px;
        text-align: center;
        color: #f00;
        
        ul{
            display: none;
        }
    }
    
    .wpcf7-response-output{
        text-align: center;
        color: #f00;
    }
    
    .form_wrap{
        padding: 0 15px;
        display: flex;
        align-items: center;
        gap: 20px;
        
        @include media_query(){
            display: block;
            position: relative;
            padding: 15px 0;
        }
        
        &::after{
            content: "*";
            color: #2872A4;
            @include rem(19);
            
            @include media_query(){
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        
        & + .form_wrap{
            border-top: solid 2px #2872A4;
        }
        
        label{
            white-space: nowrap;
            @include media_query(){
                display: block;
            }
        }
        
        input{
            width: 100%;
            flex: 1;
            // background-color: #f00;
            @include rem(19);
            padding: 1.5em 0;
            
            @include media_query(){
                display: block;
                padding: 0;
                @include rem(15);
                margin-top: 10px;
            }
        }
    }
    
    .form_body{
        position: relative;
        padding: 30px 15px;
        border-top: solid 2px #2872A4;
        border-bottom: solid 2px #2872A4;
        
        @include media_query(){
            padding: 30px 0;
        }
        
        &::after{
            position: absolute;
            right: 15px;
            bottom: 30px;
            content: "*";
            color: #2872A4;
            @include rem(19);
        }
        
        label{
            display: block;
            width: 100%;
        }
        
        textarea{
            width: 100%;
            // background-color: #f00;
            @include rem(19);
            margin-top: 10px;
            
            @include media_query(){
                @include rem(15);
            }
        }
    }
    
    .wpcf7-form-control-wrap{
        position: relative;
        display: block;
        width: 100%;
        
        .wpcf7-not-valid-tip{
            position: absolute;
            left: 0;
            bottom: 5px;
            display: block;
            color: #f00;
            @include rem(12);
        }
    }
    
    .form_submit_wrap{
        padding: 80px 0 90px;
        text-align: center;
        
        @include media_query(){
            padding: 50px 0 60px;
        }
        
        input[type=submit]{
            background-color: #2872A4;
            border-radius: 12px;
            width: 121px;
            height: 35px;
            color: #fff;
            @include rem(16);
            letter-spacing: .185em;
        }
    }
}


.screen-reader-response{
    display: none !important;
}

.sent .wpcf7-response-output {
    color: #2872A4 !important;
}
