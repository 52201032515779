@use "../base/vars" as *;

.reserve{
    margin: 30px auto 0;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 616px;
    gap: 45px;
    
    @include media_query(){
        grid-template-columns: 1fr;
    }
    
    .reserve_inner{
        padding: 60px 0 200px 120px;
        
        background-image: url(../img/reserve/bg06.webp);
        background-repeat: no-repeat;
        background-position: bottom 120px right 30px;
        background-size: 221px auto;
        
        @include media_query(){
            padding: 0 35px;
            background-image: url(../img/reserve/bg06.webp);
            background-repeat: no-repeat;
            background-position: bottom 70px right 30px;
            background-size: 138px auto;
        }
        
        .reserve_head{
            .section_title{
                margin-bottom: 65px;
                
                @include media_query(){
                    margin-bottom: 40px;
                }
            }
            
            h3{
                margin-bottom: 30px;
                @include rem(19);
                letter-spacing: .185em;
                
                @include media_query(){
                    @include rem(18);
                }
            }
            
            p{
                @include rem(15);
                letter-spacing: .15em;
                
                @include media_query(){
                    @include rem(13);
                }
            }
        }
        
        .reserve_content{
            margin-top: 60px;
            
            p{
                font-family: Lexend;
                @include rem(24);
                color: #2872A4;
                letter-spacing: .09em;
                
                a{
                    color: #2872A4;
                    text-decoration: none;
                    font-weight: bold;
                    border-bottom: solid 2px #2872A4;
                    
                    transition: border-bottom-color .25s;
                    
                    &:hover{
                        border-bottom-color: transparent;
                    }
                }
                
                & + P{
                    margin-top: 30px;
                }
            }
        }
    }
    
    .reserve_img{
        @include media_query(){
            order: -1;
            padding-left: 65px;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
}




.price{
    // max-width: 995px;
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 655px;
    gap: 30px;
    
    @include media_query(){
        grid-template-columns: 1fr;
        padding: 90px 35px 0;
        gap: 0;
    }
    
    .price_inner{
        padding: 0px 0 100px 120px;
        background-image: url(../img/reserve/bg02.webp);
        background-repeat: no-repeat;
        background-position: bottom 20px right 130px;
        background-size: 238px auto;
        
        @include media_query(){
            padding: 0 0 44px 0;
            background-position: bottom 0px right 30px;
            background-size: 238px auto;
        }
        
        .price_head{
            .section_title{
                margin-bottom: 65px;
                
                @include media_query(){
                    margin-bottom: 40px;
                }
            }
            
            h3{
                margin-bottom: 30px;
                @include rem(19);
                letter-spacing: .185em;
                
                @include media_query(){
                    @include rem(18);
                }
            }
            
            p{
                @include rem(15);
                letter-spacing: .15em;
                
                @include media_query(){
                    @include rem(13);
                }
                
                & + p{
                    margin-top: 40px;
                    
                    @include media_query(){
                        margin-top: 20px;
                    }
                }
                
                small{
                    @include rem(13);
                    
                    @include media_query(){
                        @include rem(10);
                    }
                }
            }
        }
    }
    
    .price_list{
        display: grid;
        grid-template-columns: 1fr 278px;
        gap: 50px;
        padding-top: 60px;
        
        @include media_query(){
            padding: 0;
            grid-template-columns: 1fr;
        }
        
        .price_wrap{
            
            h4{
                text-align: center;
                color: #2872A4;
                margin-bottom: 15px;
            }
            
            .price_table{
                border-top: solid 2px #2872A4;
                border-bottom: solid 2px #2872A4;
                padding: 15px 0;
                
                @include media_query(){
                    // max-width: 250px;
                    margin: 0 auto;
                }
            }
            
            table{
                width: 100%;
                
                tr{
                    th,td{
                        text-align: center;
                        @include rem(19);
                        color: #2872A4;
                        font-weight: bold;
                        padding: 15px;
                        
                        @include media_query(){
                            @include rem(18);
                        }
                    }
                    
                    th{
                        letter-spacing: .185em;
                        
                        @include media_query(){
                            padding-left: 0;
                            padding-right: 10px;
                            text-align: right;
                        }
                    }
                    
                    td{
                        small{
                            @include rem(14);
                        }
                        
                        @include media_query(){
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}






.treatment{
    max-width: 1400px;
    margin: 0 auto;
    background-image: url(../img/reserve/bg04.webp);
    background-repeat: no-repeat;
    background-position: top 55px right;
    background-size: 114px auto;
    
    @include media_query(){
        background-position: bottom right;
        background-size: 88px auto;
        // padding: 0 35px;
    }
    
    .treatment_content{
        max-width: 1280px;
        margin: 0 auto 0;
        display: grid;
        grid-template-columns: 656px 1fr;
        gap: 60px;
        padding-left: 120px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            gap: 0;
            margin-top: 62px;
            padding: 0;
        }
    }
    
    .treatment_img{
        position: relative;
        // padding-right: 65px;
        padding-right: 40px;
        
        @include media_query(){
            padding: 0 50px 45px 0;
        }
        
        img{
            width: 100%;
            height: auto;
        }
        
        .bg03{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 210px;
            height: auto;
            
            @include media_query(){
                width: 159px;
                right: 10px;
                bottom: -20px;
            }
        }
    }
    
    .treatment_inner{
        @include media_query(){
            padding: 0 35px;
        }
        .treatment_head{
            h2{
                margin-bottom: 30px;
                @include rem(19);
                letter-spacing: .185em;
            }
            
            p{
                @include rem(15);
                letter-spacing: .15em;
                line-height: 1.8;
            }
        }
        
        .treatment_flow{
            margin-top: 30px;
            border-top: solid 2px #2872A4;
            border-bottom: solid 2px #2872A4;
            padding: 30px 0;
            
            ul{
                li{
                    display: flex;
                    flex-direction: column;
                    @include rem(17);
                    letter-spacing: .185em;
                    color: #2872A4;
                    font-weight: bold;
                    
                    &:not(:last-child){
                        &::after{
                            content: "↓";
                            display: block;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }
}

.link_btn{
    margin-top: 30px;
    
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 35px;
        text-decoration: none;
        color: #fff;
        border-radius: 12px;
        background-color: #2872A4;
        font-weight: bold;
        letter-spacing: 0.185em;
        
        @include media_query(){
            @include rem(15);
        }
        
        &::after{
            display: block;
            content: "";
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #fff;
            margin-left: 10px;
        }
    }
}
