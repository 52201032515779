@use "../base/vars" as *;


html{
    scroll-behavior: smooth;
    scroll-padding-top: 182px;
    
    @include media_query(){
        scroll-padding-top: 55px;
    }
}

.wrap{
    padding: 180px 0 0 0;
    
    @include media_query(){
        padding: 100px 0 0 0;
    }
}

.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 60px 0 55px 0;
    background-color: #fff;
    
    @include media_query(){
        padding: 0;
    }
    
    .header_inner{
        max-width: 1190px;
        margin: 0 auto;
        padding: 0 20px;
        
        @include media_query(){
            display: flex;
            align-items: center;
            height: 100px;
        }
        
        h1{
            @include media_query(){
                margin-right: auto;
            }
            
            img{
                width: 100%;
                max-width: 360px;
                height: auto;
                
                @include media_query(){
                    max-width: 251px;
                }
            }
        }
        
        .nav{
            max-width: 1200px;
            margin: -20px auto 0;
            
            @include media_query(){
                position: fixed;
                left: 0;
                top: 0;
                z-index: 999;
                width: 100vw;
                height: 100vh;
                // padding-top: 30%;
                background-color: rgba($main-color, .9);
                opacity: 0;
                visibility: hidden;
                transition: opacity .4s, visibility .4s;
                margin: 0;
                
                display: flex;
                align-items: center;
                justify-content: center
            }
            
            &.active{
                opacity: 1;
                visibility: visible;
            }
            
            ul{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 30px;
                
                @include media_query(){
                    flex-direction: column;
                }
                
                li{
                    font-family: Lexend;
                    
                    &.current{
                        a{
                            color: #FCEE21;
                        }
                    }
                    
                    a{
                        opacity: .3;
                        @include rem(16);
                        letter-spacing: .09em;
                        color: $main-color;
                        text-decoration: none;
                        
                        @include media_query(){
                            color: #fff;
                        }
                        
                        &[href]{
                            opacity: 1;
                        }
                    }
                    
                    img{
                        width: 100%;
                        max-width: 23px;
                        height: auto;
                        
                        @include media_query(){
                            filter: brightness(0) invert(1);
                        }
                    }
                }
            }
        }
    }
}


.main{
    
}


.footer{
    margin-top: 100px;
    @include rem(12);
    text-align: center;
    letter-spacing: .06em;
    padding-bottom: 25px;
    
    @include media_query(){
        margin-top: 80px
    }
}


.menu-trigger,
.menu-trigger span {
    display: inline-block;
    transition: all .25s;
    box-sizing: border-box;
}
.menu-trigger {
    position: relative;
    width: 37px;
    height: 15px;
    z-index: 9999;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    
    display: none;
    
    @include media_query(){
        display: block;
    }
}
.menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $main-color;
}
.menu-trigger span:nth-of-type(1) {
    top: 0;
}
.menu-trigger span:nth-of-type(2) {
    bottom: 0;
}


.menu-trigger.active span:nth-of-type(1) {
    transform: translateY(6px) rotate(-25deg);
    background-color: #fff;
    height: 2px;
}
.menu-trigger.active span:nth-of-type(2) {
    transform: translateY(-6px) rotate(25deg);
    background-color: #fff;
    height: 2px;
}


.sp_br{
    display: none;
    
    @include media_query(){
        display: block;
    }
}

.pc_br{
    display: block;
    
    @include media_query(){
        display: none;
    }
}
