@use "../base/vars" as *;

body{
    font-feature-settings: "palt" 1;
}

.introduction{
    max-width: 980px;
    margin: 0 auto 0;
    padding: 0 20px;
    
    @include media_query(){
        padding: 0;
    }
    
    .slide{
        img{
            width: 100%;
            height: auto;
        }
    }
}

.introduction_section{
    padding: 100px 0 50px 0;
    background-image: url(../img/bg01.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 257px auto;
    line-height: 2.25;
    
    @include media_query(){
        padding: 50px 35px 0;
        
        background-size: 200px auto;
    }
    
    h2{
        @include rem(22);
        letter-spacing: .185em;
        margin-bottom: 60px;
        font-weight: normal;
        
        @include media_query(){
            @include rem(18);
            // letter-spacing: .1em;
            margin-bottom: 30px;
        }
    }
    
    p{
        @include rem(15);
        letter-spacing: .185em;
        line-height: 1.8;
        
        @include media_query(){
            @include rem(14);
        }
    }
}

.section_title{
    font-family: Lexend;
    @include rem(41);
    letter-spacing: .09em;
    color: $main-color;
    
    @include media_query(){
        @include rem(36);
    }
}

.about{
    margin: 30px auto 0;
    max-width: 1280px;
    
    @include media_query(){
        margin: 0;
        padding-top: 70px;
    }
    
    .about_inner{
        display: grid;
        grid-template-columns: 1fr 616px;
        
        @include media_query(){
            grid-template-columns: 1fr;
        }
        
        & + .about_inner{
            margin-top: 70px;
            
        }
        
        &:nth-child(even){
            grid-template-columns: 462px 1fr;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            .about_contetns{
                order: 1;
                // background-image: none;
                background-image: url(../img/bg03.png);
                background-repeat: no-repeat;
                background-position: left 90% top 30px;
                background-size: 66px auto;
                
                p{
                    @include rem(14);
                    letter-spacing: .185em;
                    line-height: 1.6;
                    text-align: justify;
                }
                
                @include media_query(){
                    // order: 0;
                    // background: none;
                    background-position: left 95% top 90%;
                }
            }
        }
        
        &#anchor{
            padding-top: 70px;
            margin: 0;
            
            @include media_query(){
                padding: 0;
            }
            
            .about_contetns{
                padding-top: 0;
                
                @include media_query(){
                    padding-top: 70px;
                }
            }
        }
    }
}


.about_contetns{
    padding: 90px 0 95px 85px;
    background-image: url(../img/bg02.png);
    background-repeat: no-repeat;
    background-position: right 35px bottom -35px;
    background-size: 226px auto;
    
    @include media_query(){
        padding: 69px 35px 50px;
        margin-bottom: 20px;
        background-size: 180px auto;
        order: 1;
    }
    
    .section_title{
        margin-bottom: 80px;
        
        @include media_query(){
            margin-bottom: 30px;
        }
    }
    
    h3{
        @include rem(28);
        letter-spacing: .185em;
        margin-bottom: 35px;
        
        @include media_query(){
            @include rem(20);
            margin-bottom: 20px;
        }
    }
    
    p{
        @include rem(15);
        line-height: 1.8;
        
        @include media_query(){
            @include rem(16);
            line-height: 2.25;
        }
        
        strong{
            @include rem(19);
            font-weight: normal;
            line-height: 2;
            letter-spacing: .185em;
            
            @include media_query(){
                @include rem(18);
                line-height: 1.8;
                letter-spacing: .1em;
                margin-bottom: 30px;
            }
        }
        
        & + p{
            margin-top: 50px;
            
            @include media_query(){
                margin-top: 1em;
            }
        }
    }
    
    .recommend{
        margin-top: 80px;
        
        @include media_query(){
            margin-top: 40px;
        }
        
        h4{
            @include rem(19);
            letter-spacing: .185em;
            
            @include media_query(){
                @include rem(17);
            }
        }
        
        .recommend_inner{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px 50px;
            padding: 35px 20px 45px 0;
            // background-image: url(../img/bg03.png);
            // background-repeat: no-repeat;
            // background-position: left 80% bottom;
            // background-size: 66px auto;
            
            @include media_query(){
                grid-template-columns: 1fr;
                gap: 20px;
                // background-size: 50px auto;
                // background-position: right 20px center;
                padding: 0;
                margin: 30px 0 0 0;
            }
            
            .recommend_item{
                h5{
                    display: flex;
                    align-items: center;
                    
                    span{
                        display: grid;
                        place-content: center;
                        width: 60px;
                        height: 60px;
                        border: solid 3px $main-color;
                        border-radius: 100%;
                        
                        @include rem(35);
                        color: $main-color;
                        font-weight: bold;
                        
                        @include media_query(){
                            width: 50px;
                            height: 50px;
                            @include rem(30);
                        }
                    }
                    
                    p{
                        @include rem(20);
                        font-weight: bold;
                        line-height: 1.2;
                        letter-spacing: .085em;
                        color: $main-color;
                        padding-left: 20px;
                        margin: 0;
                        
                        @include media_query(){
                            flex: 1;
                        }
                    }
                }
                
                p{
                    margin-top: 10px;
                    @include rem(12);
                    letter-spacing: 0.075em;
                }
            }
        }
    }
}



.enterprise{
    max-width: 930px;
    margin: 0 auto 85px;
    
    @include media_query(){
        margin: 0 0 0;
        padding: 0 35px;
    }
    
    .enterprise_inner{
        h3{
            @include rem(28);
            letter-spacing: .185em;
            margin-bottom: 35px;
            
            @include media_query(){
                @include rem(20);
                margin-bottom: 20px;
                letter-spacing: .1em;
            }
        }
        
        p{
            @include rem(15);
            line-height: 1.8;
        }
        
        .btn_reserve{
            margin-top: 40px;
            
            @include media_query(){
                margin-top: 20px;
            }
            
            a{
                width: 100%;
                max-width: 240px;
                @include rem(17);
                letter-spacing: .185em;
                font-weight: bold;
                
                @include media_query(){
                    width: 190px;
                    @include rem(14);
                }
            }
        }
    }
}



.about_img{
    overflow: hidden;
    
    img{
        max-width: 100%;
        height: auto;
        
        @include media_query(){
            aspect-ratio: 4 / 3;
            object-fit: cover;
            position: relative;
            left: 50px;
        }
    }
    
    &.about_img_bone{
        img{
            @include media_query(){
                aspect-ratio: 313 / 417;
                object-fit: cover;
                max-width: 313px;
                left: 0;
            }
        }
    }
}

.director{
    background-image: url(../img/bg04.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 147px auto;
    
    @include media_query(){
        margin: 80px 0 0 0;
        // background-position: right bottom;
        background-position: left -30px top 58px;
        background-size: 150px auto;
    }
    
    .director_inner{
        max-width: 920px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 317px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            padding: 0 35px;
        }
    }
    
    .title{
        @include rem(16);
        letter-spacing: .185em;
        margin-bottom: 1em;
        
        @include media_query(){
            @include rem(14);
        }
    }
    
    .name{
        @include rem(28);
        letter-spacing: .185em;
        font-weight: normal;
        
        @include media_query(){
            @include rem(24);
        }
        
        span{
            @include rem(16);
            letter-spacing: .185em;
            padding-left: 25px;
            
            @include media_query(){
                @include rem(14);
            }
        }
    }
    
    .text{
        margin-top: 43px;
        @include rem(15);
        letter-spacing: .115em;
        line-height: 1.6;
        padding-right: 84px;
        text-align: justify;
        
        @include media_query(){
            margin-top: 30px;
            padding: 0;
        }
    }
    
    .director_img,
    .director_img_sp{
        
        @include media_query(){
            order: -1;
            margin-bottom: 25px;
            text-align: center;
        }
        
        img{
            width: 100%;
            height: auto;
            
            @include media_query(){
                max-width: 207px;
                // aspect-ratio: 4 / 3;
                // object-fit: cover;
                // object-position: center -80px;
            }
        }
    }
    
    .director_img_sp{
        display: none;
        
        @include media_query(){
            display: block;
            margin: 34px 0 34px;
        }
    }
    
    .director_img{
        @include media_query(){
            display: none;
        }
    }
}

.access{
    
    // margin: 60px 0 0 0;
    
    @include media_query(){
        margin: 0;
    }
    
    .access_inner{
        background-image: url(../img/bg05.png);
        background-repeat: no-repeat;
        background-position: right 20px top;
        background-size: 383px auto;
        
        max-width: 1280px;
        margin: 0 auto;
        padding: 55px 0 0 85px;
        
        @include media_query(){
            background-position: center top -30px;
            background-size: 300px auto;
            padding: 100px 0 0 0;
        }
        
        .section_title{
            margin-bottom: 50px;
            
            @include media_query(){
                padding: 0 35px;
            }
        }
    }
    
    .access_contents{
        display: grid;
        grid-template-columns: 384px 1fr;
        gap: 130px;
        
        @include media_query(){
            display: block;
        }
        
        .access_img{
            img{
                width: 100%;
                height: auto;
            }
        }
        
        .access_text{
            @include media_query(){
                width: 100%;
                padding: 30px 35px 0;
            }
            
            h3{
                @include rem(23);
                font-weight: normal;
                margin-bottom: 30px;
                letter-spacing: .185em;
            }
            
            p{
                @include rem(15);
                letter-spacing: .115em;
                line-height: 1.86;
                
                small{
                    @include rem(11);
                    letter-spacing: .025em;
                }
            }
            
            .info{
                // display: flex;
                // align-items: center;
                // gap: 45px;
                margin-top: 35px;
                
                .map{
                    @include rem(15);
                    font-weight: bold;
                    
                    @include media_query(){
                        @include rem(14);
                    }
                    
                    a{
                        color: $font-color;
                        text-decoration: none;
                        border-bottom: solid 1px #000;
                        padding-bottom: 5px;
                    }
                }
                
                h4{
                    @include rem(17);
                    letter-spacing: .185em;
                    margin: 40px 0 20px;
                    color: $main-color;
                }
            }
        }
    }
}

.btns{
    display: flex;
    gap: 30px;
    
    @include media_query(){
        gap: 0;
        justify-content: space-between;
    }
}

.btn_reserve{
    a{
        @include rem(17);
        font-weight: bold;
        background-color: $main-color;
        width: 158px;
        height: 46px;
        letter-spacing: .145em;
        display: grid;
        place-content: center;
        border-radius: 12px;
        color: #fff;
        text-decoration: none;
        
        @include media_query(){
            width: 158px;
            @include rem(14);
        }
        
        &:hover{
            opacity: .7;
        }
    }
}

.btn_reserve_l{
    margin-top: 16px;
    a{
        width: 100%;
        max-width: 268px;
        
        @include media_query(){
            width: 100%;
            max-width: 250px;
        }
    }
}
